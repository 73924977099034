import GSAP from 'gsap';

import ImageHoverEffect from '../../components/@avery-image-hover-effect';
import AboutSection from './AboutSection';

import Page from '../../classes/Page';

import each from 'lodash/each';
import SelectionTextSection from './SelectionTextSection';
import PricingSection from './PricingSection';
import FaqSection from './FaqSection';
import VisSection from './VisSection';

export default class HomePage extends Page {
	constructor(el) {
		super({
			element: el,
			elements: {
				title: '.cover__title',
				list: 'li',
				desc: '.cover__desc--hidden',
				btn: '.btn',
			},
		});
		this.id = 'home';
		this.isCreated = false;
	}

	create() {
		if (this.template != this.id) return;

		if (!this.isReady) super.createComponent();

		if (!this.isCreated) {
			this.components = {
				selection: new ImageHoverEffect({
					element: '.selection',
					elements: {
						figure: '.selection__figure',
						content: [
							{
								image: '#selection-designs',
								button: '.selection__button--design',
							},
							{
								image: '#selection-websites',
								button: '.selection__button--website',
							},
						],
					},
					activeClass: 'selection__image--active',
				}),
				about: new AboutSection(),
				selectionText: new SelectionTextSection(),
				pricing: new PricingSection(),
				faq: new FaqSection(),
				// vis: new VisSection(),
			};
			this.isCreated = true;
		}
		// Create components
		each(this.components, (component) => {
			component.create();
			if (component.addEventListeners) component.addEventListeners();
		});
		console.log(`🔼 ${this.id} is created`);
	}

	show() {}

	hide() {
		return new Promise((resolve) => {
			this.destroy();

			GSAP.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});
		});
	}

	onResize() {
		if (this.element) this.element.style.height = `${window.innerHeight}px`;
	}

	addEventListeners() {
		each(this.components, (component) => {
			component.addEventListeners();
		});
	}

	removeEventListeners() {
		each(this.components, (component) => {
			component.removeEventListeners();
		});
	}

	destroy() {
		super.destroy();
		this.removeEventListeners();

		each(this.components, (component) => {
			component.destroy();
		});

		// Removes scroll trigger instances
		const scrolltriggerElements = document.querySelectorAll('.pin-spacer');
		each(scrolltriggerElements, (pinSpacer) => {
			const parent = pinSpacer.parentElement;

			while (pinSpacer.firstChild) {
				parent.appendChild(pinSpacer.firstChild);
			}

			parent.removeChild(pinSpacer);
		});
	}

	update() {
		if (
			this.components &&
			this.components.selection &&
			this.components.selection.isReady
		)
			this.components.selection.update();
	}
}
